<template>
   <div>
        <el-form :label-position="labelPosition" label-width="70px" :model="user" :rules="rules"  ref="ruleForm"  label="left" status-icon  class="myform">
        <el-form-item 
            prop="name"
            style="color: black;"
        >
        <span slot="label" style="color: black;"   >手机号</span>
            <el-input v-model="user.name"  status-icon autocomplete="off" type="text" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item 
             prop="region"
        >
        <span slot="label" style="color: black;"   >验证码</span>
            <el-col :span="13">
              <el-input v-model="user.region" status-icon autocomplete="off"  type="text" placeholder="请输入验证码"></el-input>
            </el-col>
            <el-col :span="10" :offset="1">
              <el-button type="primary" style="margin-left: 10px;width: 120px;" @click="authcode()" v-if="flag">获取验证码</el-button>
              <el-button type="primary" style="margin-left: 10px;width: 120px;" v-if="!flag" >{{ time }} S</el-button>
            </el-col>
          </el-form-item>
        </el-form>
              <div>
                <el-button type="success"   class="number" @click="login()">登录</el-button>
            </div>
            <div>
              <el-button type="danger"   class="icon" @click="resetForm('ruleForm')">重置</el-button>
            </div>
   </div>
</template>

<script >
    import {loginphone,login}  from "@/api/index"
    // import LoginByname from "../src/components/LoginByname"
  export default {
    data() {
      return {
        labelPosition: 'right',
        user: {
          name: '',
          region: '',
        },
        time:60,
        flag:true,
        number:'',
        parent:"",
        appkey2:"",
        min:"",
        rules: {
          name: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '手机号为11位数', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请输入验证码',  trigger: 'blur' }
          ],
        }
      };
    },
    methods:{
      //发送验证码
      async loginphone(){
          let data = {
            phone:this.user.name,
            type1:42
          }
          let res = await loginphone(data)
          if(res.data.code == 200){
            this.$message({
                message: '验证码发送成功',
                type: 'success'
              });
          }else{
            this.$message.error(res.data.message);
          }
          // console.log(res)
      },

      //登录
      async loginnumber(){
          let data = {
            phone:this.user.name,
            keyCode:this.user.region,
            sign:this.number
          }
          let res = await login(data)
          if(res.data.code == 200){
            this.$message({
                message: '登录成功',
                type: 'success'
              });
              sessionStorage.setItem("sk_access_id",res.data.data.list.sk_access_id)
              sessionStorage.setItem("access_id",res.data.data.list.access_id)
              this.$router.push({path:'/background'})
          }else{
            // this.addnumber()
            this.$message.error(res.data.message);
          }
          // if(res.data.data == 200){
          //     this.$router.push({path:'/background'})
          // }
          // console.log(res)
      },
      //重置表单
      resetForm(formName) {
        this.user.name = ''
        this.user.region = ''
        this.$refs[formName].resetFields();
      },
      addnumber(){
          const citys = {
            phone: this.user.name,
            keyCode : this.user.region,
            // a:1,
            // A:2,
          }
          var obj = {}
          Object.keys(citys).sort().map(item => {
              obj[item] = citys[item]
          })
          // console.log(obj,"排序后")
          var listArr = [ ]
          Object.keys(obj).forEach(function (key) {
             listArr.push(obj[key])
          });
          // console.log(listArr)
          this.number = listArr.toString()
          this.number = this.number.replace(/[,]/g, '')
          // this.number=this.number.slice(0,this.number.length-1)
          // console.log(this.number)
          var appkey = "laQ092nkwxCKHRUjt2hizoXr8lbrtdow"
          this.number = this.number + appkey
          // console.log(this.number,"账号验证码+appkey")
          this.number = this.$md5(this.number)
          // console.log(this.number,"转为md52")
          sessionStorage.setItem("sign",this.number)
          this.loginnumber()
      },
      //登录
      login(){
        //  this.$router.push({path:'/background'})
        if(this.user.name == ''||this.user.region==''){
            this.$message({
            message: '手机号或验证码不能为空',
            type: 'warning'
          });
          // this.addnumber()
        }else{
          // this.loginnumber()
          this.addnumber()
        }
      },

      
      authcode(){
        if(this.user.name==""){
          this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
        }else{
          this.flag = false
          this.addtime()
          this.loginphone()
        }
      },

      //验证码倒计时
      addtime(){
        let count=setInterval(()=>{
          this.time--
          if(this.time==0){
            clearInterval(count)
            this.flag = true
            this.time = 60
          }
        },1000)
      }
    }
  }
</script>

<style scoped>
    .number{
        width: 95%;
        height: 40px;
        margin-left:5%;
    }

    .icon{
        width: 95%;
        height: 40px;
        margin-top: 20px;
        margin-left:5%;
    }
    
</style>