
//1. 引入axios

import axios from "axios"

const baseURL = ''

//2. 配置环境变量
if (process.env.NODE_ENV == "development") {
  const baseURL = 'http://api.hxnc.ikuyoo.cn' // (开发环境)
  axios.defaults.baseURL = baseURL ; 
	console.log('development')
}
if (process.env.NODE_ENV == "production") {
  // const baseURL = 'https://api.hxnc.cc' // (生产环境)
  const baseURL = 'http://api.hxnc.ikuyoo.cn'
  axios.defaults.baseURL = baseURL ; 
	console.log('production')
}
// const baseURL = 'http://api.hxnc.ikuyoo.cn' // (开发环境)
// axios.defaults.baseURL = baseURL ; 
// const baseURL = '' // (生产环境)


// //3. 自定义请求拦截器
// axios.interceptors.request.use(config=>{
//     //配置token请求头
//     const token = sessionStorage.getItem("token")
//     if(token){
//         config.headers.token = token; //请求头
//     }
//     return config;
// },(error)=>{
//   //请求失败时
//   return  Promise.reject(error) 
// })

 //3. 自定义请求拦截器
axios.interceptors.request.use(
  (config) => {
    // const token = sessionStorage.getItem("access_id")
    // if(token){
    //           config.headers.token = token; //请求头
    //   }
    config.params = {
      ...config.params,
      // access_id: sessionStorage.getItem("access_id"),
    };
    return config;
  },
  (config) => {
    // 可使用async await 做异步操作
    return Promise.reject(config);
  }
);

//4.自定义响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;  //返回响应的消息
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);  //返回错误的消息
  }
);

// //3. 自定义请求拦截器
// axios.interceptors.request.use(config=>{
//     //配置token请求头
//     const token = sessionStorage.getItem("token")
//     if(token){
//         config.headers.token = token; //请求头
//     }
//     return config;
// },(error)=>{
//   //请求失败时
//   return  Promise.reject(error) 
// })

// //4. 自定义响应拦截器
// axios.interceptors.request.use((response)=>{
//     return response    //返回响应的消息
// },
//     (error)=>{
//         return Promise.reject(error);    //返回错误的消息
//     }
// )


//5. 配置模块化api 
//   GET 请求
// export const get = (url,params,headers)=>{
//     return new Promise((resolve,reject)=>{
//         axios({
//             url,
//             method:"GET",
//             params,  //请求参数
//             headers  //请求头
//         })
//         .then(res=>{
//             resolve(res.data)   //成功回调
//         })
//         .catch(err=>{
//             reject(err)    // 失败回调
//         })
//     })
// }

// //  POST 请求
// export const post = (url,params,headers)=>{
//     return new Promise((resolve,reject)=>{
//         axios({
//             url,
//             method:"POST",
//             params,
//             headers
//         })
//         .then(res=>{
//             resolve(res.data)
//         })
//         .catch(err=>{
//             reject(err)     
//         })
//     })
// }

//6. 导出
export {
    axios,
    baseURL
}