//1.引入vue
import Vue from 'vue'
//2.引入 vue-router
import VueRouter from 'vue-router'
//3.实例化路由
Vue.use(VueRouter);

// 4. 定义路由组件

//5.定义路由
const router  = new VueRouter({
    mode:"hash", // 1.history路由  2.hash路由    
                    // history路由 后面无#   hash路由后面有#         
    routes:[
        {
            path:'/',
            name:"index",
            redirect:'/login'     //设置路由重定向
        },
        {
            path:"/login",
            name:'login',
            component:()=>import('../view/LoGin.vue')    //路由懒加载   
            //懒加载优点：在页面跳转的时候进行加载，提升了页面的流畅性
        },
        {
            path:"/background",
            name:"background",
            component:()=>import('../view/BackGround.vue'),
            children:[
                {
                    name:'index',
                    path:'/background',
                    redirect:"/background/service"
                },{
                    name:"service",
                    path:"/background/service",
                    component:()=>import('../service/SerVice.vue'),
                }
            ]
        },
    ]
})



export default router    //将路由导出